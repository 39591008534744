/* ////////////////////// General //////////////// */
body, html {
  margin:0;
  padding:0;
  height: 100%;
  width:100%;
  background-color: #edf1d6;
  overflow-x: hidden;
}

.spinnermain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

/* ///////////// Main Page ////////////////////// */
.mainpage {
  margin-top: 100px;
  margin-bottom: 100px;
  overflow-x: hidden;
  min-height: 100vh;
}

.firstheading {
  font-size: 50px;
  font-weight: bold;
  color: #609966;
  text-align: center;
  margin-bottom: 10px;
}

.secondheading {
  font-size: 30px;
  color: #40513b;
  text-align: center;
  margin-bottom: 150px;
}

.mainpageimage {
  width: 70%;
  height: auto;
}

.mainpagetextcol{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mainpagetext {
  font-size: 1em;
  color: #40513b;
  text-align: justify;
  /* margin-bottom: 50px;
  margin-top: 15px; */
}


.sectionheading {
  font-size: 20px;
  font-weight: bold;
  color: #609966;
  text-align: left;
  margin-bottom: 10px;
}

/* //////////////////// Navbar ///////////////////// */
.navbar {
  background-color: #609966 !important;
  width: 100%;
  transition: all 0.3s ease-out 0s;
  font-size: 1rem;
  font-family: "Montserrat";
  color: white;
}

.logo-img {
  border-radius: 50%;
  height: 70px;
}

.navbar-toggler,
.navbar-toggler {
  color: #9dc08b;
  border: none;
}

.navbar-toggler-icon {
  background-image: url("../assets/images/navbar-toggler.svg") !important;
}

.navbarbuttons {
  font-weight: bold;
}

.nav-link {
  color: #edf1d6;
}

.nav-link:hover {
  color: #9dc08b;
}

/* /////////////////////// Footer ///////////////////// */

.footer {
  position: relative;
  bottom: 0 !important;
  width: 100%;
  height: auto;
  min-height: 10vh;
  color: #edf1d6;
  background-color: #609966;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#footerbottomtext {
  font-size: 0.8em;
  color: #edf1d6;
}

.privacypolicy {
  text-decoration: none;
  cursor: pointer;
  color: #edf1d6;
}

@media (max-width: 768px) {
  .footer {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* //////////////////////// Sign Up Sign In ////////////////// */
.signup-subheading {
  font-size: 1.25rem;
  margin-top: 1.5rem;
  font-weight: 600;
}

.fieldtextarea {
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  color: #4b5563;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    color 0.15s ease-in-out;
}

/* Focus state */
.fieldtextarea:focus {
  color: #4b5563;
  background-color: #ffffff;
  border-color: #64748b;
  outline: none;
}

.addproj-subheading {
  font-size: 1.25rem;
  margin-top: 1.5rem;
  font-weight: 600;
}

.hashtag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.hashtag {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
}

.hashtag-input-container {
  display: flex;
  gap: 8px;
}

.pw-section {
  margin-bottom: 1.5rem;
  position: relative;
}

.email {
  width: 100%;
  padding: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 1rem;
  color: #4b5563;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  transition: all 0.15s ease-in-out;
}

.eye-icon {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.forgotpw-button {
  color: #3b82f6;
  transition: color 0.2s ease-in-out;
}

.forgotpw-button:hover {
  color: #2563eb;
}

.forgotpw-button:active {
  color: #1e40af;
}

.register-button {
  color: #ef4444;
  transition: color 0.2s ease-in-out;
  margin-left: 0.25rem;
}

.register-button:hover {
  color: #dc2626;
}

.forgot-pw {
  color: #3b82f6; /* Blue-600 */
  transition-duration: 200ms;
}

.forgot-pw:hover {
  color: #1d4ed8; /* Blue-800 */
}

.signin {
  color: #ef4444; /* Red-600 */
  transition-duration: 200ms;
  margin-left: 0.25rem; /* Equivalent to ml-1 */
}

.signin:hover {
  color: #dc2626; /* Red-700 */
}

/* ///////////// Main Sign In Page ///////////////  */

/* SignIn.css */




.signinbox {
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 250px;
  height: 150px;
  background-color: #40513B;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  transition: background-color 0.2s ease-in-out;
  color: white;
}


.signinbox:hover {
  background-color: #9DC08B;

  color: #fff;
}

.signinbox:hover img {
  filter: brightness(200%);
}

.signinbox:hover span {
  color: #fff; /* Text color on hover */
}

/* //////// Sign In and Sign In Up Main Pages ///////  */


.formcontainer {
  margin-left: 20px;
  text-align: right;
}

.signinupcontainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px;
  padding-top: 12px;
  margin: auto;
}

.signin-button {
  width: auto;
  background-color: #40513b;
  color: #ffffff;
  padding: 0.875rem 1.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.signin-button:hover {
  background-color: #394d33;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.signin-button:active {
  background-color: #273e20;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1);
}

.text-section {
  margin-bottom: 1.2rem;
}

.signin-section {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.sign-up-header {
  font-size: 2.4rem;
  text-align: center;
  margin-top: 1.5rem;
}

/* /////// Sign Up Pages ////////// */

.signupcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  align-items: center;
  padding: 6px;
  padding-top: 12px;
  padding-bottom: 20px;
  margin: auto;
}

.signupleftcolumn {
  width: 50%;
}

.signuprightcolumn {
  width: 50%;
}

@media (max-width: 768px) {
  .signupcontainer {
    max-width: 100vw;
  }

  .signupleftcolumn {
    width: 100%;
  }

  .signuprightcolumn {
    width: 100%;
  }
  .formcontainer {
    margin-left: 0;
    text-align: center;
  }
}

.image-subheading {
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.imagesinput {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  color: #4a5568;
  background-color: #ffffff;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.buttoncontainer {
  text-align: right;
}

.sociallinks-add-button {
  padding: 8px 12px;
  background-color: #394d33;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sociallinks-add-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.vendorsignupcolumn {
  width: 100%;
}

.nextbutton {
  margin-top: 1.5rem;
}



/* //////////////////////////Profiles //////////////////// */
.profile-section {
    max-width: 6xl;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .profile-form {
    margin-top: 1.5rem;
    padding: 0.75rem;
  }
  
  .profile-nameinput {
    margin-bottom: 1.5rem;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    color: #4a5568;
    background-color: #fff;
    border: 1px solid #d2d6dc;
    border-radius: 0.375rem;
    transition: ease-in-out;
  }
  
  .profile-nameinputedit {
    background-color: #fee2e2;
  }
  
  .profile-nameinputedit:focus {
    background-color: #fee2e2;
  }
  
  .profile-email {
    margin-bottom: 1.5rem;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    color: #4a5568;
    background-color: #ffffff;
    border: 1px solid #d2d6dc;
    border-radius: 0.375rem;
    transition: ease-in-out;
  }
  
  .edit-container {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .changeornot {
    display: flex;
    align-items: center;
  }
  
  .edit-button {
    color: #dc2626;
    transition: color 200ms ease-in-out;
    cursor: pointer;
    margin-left: 0.25rem;
  }
  
  .edit-button:hover {
    color: #d1d5db;
  }
  
  .signout-button {
    color: #3b82f6;
    transition: color 200ms ease-in-out;
    cursor: pointer;
  }
  
  .signout-button:hover {
    color: #2563eb;
  }
  
  .myprojects {
    max-width: auto;
    padding: 3px;
    margin-top: 6rem;
    margin-right: auto;
    margin-left: auto;
  }
  
  .myprojects-heading {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  
  .profile-image {
    height: 50%;
    width: 50%;
    position: relative;
  }
  
  
  /* ///////// Updated CSS ////////// */
  .vendorprofilecontainer {
    margin-top: auto;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-items: center;
    padding: 6px;
    padding-top: 12px;
    margin: auto;
  }
  
  .vendorprofiletopright {
    text-align: left;
    margin-left: 5%;
  }
  
  @media (max-width: 768px) {
    .vendorprofiletopright {
      text-align: center;
    }
  
    .vendorprofilecontainer {
      justify-content: center;
    }
  
    .vendorprofile-heading {
      text-align: center;
    }
  }
  
  .vendorprofile-heading {
    font-size: 1.5rem;
    text-align: left;
    margin-top: 1.5rem;
    font-weight: bold;
    color: #273e20;
  }

  .seepublic-heading {
    font-size: 1.5rem;
    text-align: left;
    margin-top: 1.5rem;
    font-weight: bold;
    color: #437035;
  }
  
  .vendorprofiledescription {
    font-size: 1.2rem;
  }
  
  .profilecategorybox {
    margin-top: 20px;
    text-align: left;
    justify-content: left;
    display: inline; /* or inline-block */
  }
  
  .vendorcategorydescription {
    font-size: 1.2rem;
    display: inline; /* or inline-block */
    width: auto;
  }
  
  .category-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline; /* or inline-block */
  }
  
  .category-item {
    display: inline-block;
    padding: 10px;
    border: 1px solid #d2d2d2; /* Border color for each forte item */
    border-radius: 5px;
    margin: 0 10px 10px 0; /* Margin around each forte item */
  }
  
  .addnewproject-button {
    width: 100%;
    background-color: #40513b;
    color: #fff;
    text-transform: uppercase;
    padding: 0.75rem 1.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
    cursor: pointer;
  }
  
  .addnewproject-button:hover {
    background-color: #394d33;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .addnewproject-button:active {
    background-color: #273e20;
  }
  
  .post-new-container {
    position: relative;
    display: inline-block;
  }
  
  .post-new-button {
    background-color: #40513b;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .post-new-button:hover {
    background-color: #394d33;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .post-new-button:active {
    background-color: #273e20;
  }
  
  .vendordropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .vendordropdown-link {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s;
  }
  
  .vendordropdown-link:hover {
    background-color: #f0f0f0;
  }
  
  .invdlprojcover-image {
    width: 100%;
    max-height: 70vh;
    overflow: hidden;
    border: 2px solid #716a6a;
    border-radius: 5px;
    margin-left: 14px;
    object-fit: cover;
  }
  
  .invdlprofile-image-invdlcompanypage {
    height: 33vh;
    width: 100%;
    object-fit: contain;
    min-width: 33vh;
    max-width: 33vh;
    max-height: 100%;
    border: 3px solid #5aa371;
    box-shadow: 0 4px 8px #207d2f;
  }










  /* ///////////////////////// Invdl Profiles /////////////// */
  .copylinkto-proj {
    position: fixed;
    top: 13%;
    right: 3%;
    z-index: 10;
    background-color: white;
    cursor: pointer;
    border: 2px solid #b0b0b0;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copylink-text {
    font-size: 1.125rem; /* Equivalent to 18px (text-lg) */
    color: #6b7280; /* Equivalent to text-slate-500 */
  }

  .linkcopiedtext {
    position: fixed;
    top: 23%;
    right: 5%;
    font-weight: 600; /* Equivalent to font-semibold */
    border: 2px solid #cbd5e0; /* Equivalent to border-gray-400 */
    border-radius: 0.375rem; /* Equivalent to rounded-md */
    background-color: #fff; /* Equivalent to bg-white */
    z-index: 10; /* Equivalent to z-10 */
    padding: 0.5rem; /* Equivalent to p-2 */
  }


  .projdetails-wrapper {
    margin: 1rem; /* Equivalent to m-4 */
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .projdetails-wrapper {
      flex-direction: row;
    }
  }
  
  .projdetails-wrapper > * {
    flex: 1;
  }
  
  @media (min-width: 1024px) {
    .projdetails-wrapper {
      max-width: 72rem; /* Equivalent to max-w-6xl */
      margin-left: auto; /* Equivalent to lg:mx-auto */
      margin-right: auto; /* Equivalent to lg:mx-auto */
      padding: 1rem; /* Equivalent to p-4 */
      border-radius: 0.375rem; /* Equivalent to rounded-lg */
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /* Equivalent to shadow-lg */
      background-color: #fff; /* Equivalent to bg-white */
    }
  
    .projdetails-wrapper > * {
      margin-right: 1rem;
    }
  }

  .projdetails-text {
    width: 100%;
  }

  

  .professionalprojectdetails {
    font-size: 2xl;
    font-weight: bold;
    margin-bottom: 0.75rem;
    color: #1a4d80;

    display: flex;
    align-items: center;
  }


  .projdetails-texttwo {
    display: flex;
    align-items: center; 
    margin-top: 1.5rem; 
    margin-bottom: 0.75rem;
    font-weight: 600; 
  }

 


  /* ///// Professional Projects Single Page /////// */

.professionalsingleproject{
  width: 100%;
}


.cover-image {
  width: 100%;
 height: 100%;
}


/* //////// Vendor Link Cards ////////  */
  
.vendorlinkcards{
  display: flex;
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 40px;

}

.vendorlcard{
  position: relative; 
  border-radius: 0.25rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  width: 270px;
  height: 250px;
  overflow: hidden;
}

.vendorlcard:hover {
  box-shadow: 0 8px 16px #267d20;
  
}

.vendorlcardimg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .vendorlcard .card-title {
    font-size: 1rem;
    text-align: center;
  }
  
  .vlcard-text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #09090986;
    color: #fff;
  }
  
  .vendorlcard .card-body {
    padding: 10px;
  }
  
  .vlcustom-link {
    text-decoration: none;
    color: inherit; /* Inherit the text color from the parent element (Card.Title) */
    cursor: pointer; /* Show pointer cursor on hover */
  }
  
  .vlcustom-link:hover {
    /* color: inherit; Use the same text color as the parent element on hover */
    color: #e1dcdc;
  }


.invdlprojcover-imagecontainer{
  width: 100%;

}



.invdlprofilecontainer-invdlcompanypage{
  margin-top: auto;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: left;
  /* flex-wrap: wrap; */
  align-items: center;
  padding: 6px;
  padding-top: 12px;
  margin: auto;
}



/* //////////////// Branches Page ////////// */


.branch-section {
  margin-bottom: 20px;
}

.branch-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.branch-address, .branch-contact {
  margin-bottom: 5px;
}
  














/* //////////////////////////////// Add Restaurant Items ////////////// */


.addproj-heading {
    font-size: 3rem; 
    text-align: center; 
    margin-top: 1.5rem; 
    font-weight: bold; 
  }


  .imagetext {
    color: #718096; 
  }
  

  .image-submitbox {
    margin-bottom: 1.5rem; 
  }

  .hashtag {
    background-color: #f0f0f0;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
 
  .hashtag-add-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .hashtag-add-button:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
  



  /* ////// Updated CSS ////  */
  .addprojcontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 6px;
    padding-top: 12px;
    margin: auto;
  }
  

  
  .addproj-subheadingtwo {
    margin-top: 1.5rem;
    font-size: 1.125rem; 
    font-weight: 600; 
  }


  
.imagesinputtwo {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  color: #4a5568; 
  background-color: #ffffff; 
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem; 
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}




.finalsubmit-button {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: auto;
  padding: 0.75rem 1.75rem;
  background-color: #40513B; 
  color: #ffffff; 
  font-size: 0.875rem; 
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 1px rgba(0, 0, 0, 0.1); 
}

.finalsubmit-button:hover,
.finalsubmit-button:focus {
  background-color: #394d33; 
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 2px rgba(0, 0, 0, 0.15); 

}

.finalsubmit-button:active {
  background-color: #273e20; 
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 1px rgba(0, 0, 0, 0.1), 0 2px 6px 2px rgba(0, 0, 0, 0.15); 
}






/* ////////////////Menus Page Cards /////////////////// */


.restaurant-card {
  position: relative;
  width: 200px; /* Equal width and height to make the card circular */
  height: 200px;
  border-radius: 50%; /* Make the card circular */
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.restaurant-card:hover {
  box-shadow: 0 8px 16px #40513B;
}

.restaurant-card .card-title {
  font-size: 0.9rem;
  margin-top: 10px;
}


.restaurant-card .card-text {
  font-size: 0.8rem;
  color: #e2e2e2;
}

.restaurant-time {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #609966;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  padding: 2px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Image Styles */
.restaurant-card-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Ensure image is circular as well */
  transition: transform 0.2s ease-in;
}
.restaurant-card-img:hover {
  transform: scale(0.95);
}


@media (max-width: 768px) {
  .restaurant-card{
    width: 40vw;
    height: 20vh;
  }

  
.restaurant-card .card-title {
  font-size: 0.5rem;
}

.restaurant-card .card-text {
  font-size: 0.4rem;
}

.restaurant-time {
  font-size: 8px;
  font-weight: 400;
}
}




.card-text-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(39, 93, 51, 0.488);
  color: #fff;
  text-align: center;
  border-bottom-left-radius: 50%; /* Slight curve at the bottom */
  border-bottom-right-radius: 50%;
}



.restaurant-card .card-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Adjust link appearance */
.custom-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}


.custom-link:hover {
  /* color: inherit; Use the same text color as the parent element on hover */
  color: #40513B;
}

@media (max-width: 768px) {
  .restaurant-card {
    width: 150px;
    height: 150px;
  }
  
  .restaurant-card .card-title {
    font-size: 0.7rem;
  }

  .restaurant-time {
    font-size: 8px;
    font-weight: 400;
  }

  .restaurant-card .card-text {
    font-size: 0.5rem;
  }
}


.restaurant-delete {
  position: relative;
  bottom: 2px;
  right: 4px !important;
  height: 14px;
  cursor: pointer;
  color: #ef4444; 
}


.restaurant-edit {
  position: relative;
  bottom: 2px;
  left: 2px;
  height: 16px;
  cursor: pointer;
  color: #3ea05a;
}


.signincontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}



/* /////////////////////////// Sign Up Page New CSS ///////////////// */
.signuprightsidenew{
  margin:0 auto;
  margin-top: 50vh;
  transform: translateY(-50%);
  text-align: center;
}


.ripple:hover {
  background: #9DC08B !important;
}
.ripple:active {
  background-color: #a4ba9a !important;
  background-size: 100%;
  transition: background 0s;
}


.ripple, .rippletwo {
  background-position: center;
  transition: background 0.8s;
}

.rippletwo:hover {
  background: #609966 !important;
}
.rippletwo:active {
  background-color: #709474 !important;
  background-size: 100%;
  transition: background 0s;
}





.signuprightsideheading{
  padding-top: 20px;
  text-align: center;
  color: #40513B;

}

.signuprightsidepara{
  text-align: center;
  color: #40513B;
}


#signuprightbutton{
  border: none;
  border-radius: 2px;
  padding: 12px 18px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: #609966;
  box-shadow: 0 0 4px #999;
  outline: none;
}

#signupleftbutton{
  border: none;
  border-radius: 2px;
  padding: 12px 18px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: #40513B;
  box-shadow: 0 0 4px #999;
  outline: none;
}



.signuprightcolnew{
  background-color:#EDF1D6;
  min-height: 100vh;
}


.signupleftcolnew{
  background-color:#9DC08B;
  min-height: 100vh;
}

.signupleftsideheading{
  padding-top: 20px;
  text-align: center;
  color: #EDF1D6;

}

.signupleftsidepara{
  text-align: center;
  color: #EDF1D6;
}


.signupleftbutton{
  outline: none !important;
  font-family:Lobster;
  margin-bottom:15px;
  border:none;
  font-size:20px;
  padding:8px;
  padding-left:20px;
  padding-right:20px;

  background-color:#40513B;
  border-radius:5px;
 
}



/* /////////////////Sign In Page New ///////////////// */

.leftdivcard{
  height: auto;
  margin:0 auto;
  background-color: #9DC08B;
  box-shadow: 10px 10px 1px 0px rgba(80, 205, 78, 0.2);
  border-radius:10px;
  padding: 20px;
}



.emailnew{
  font-size:16px;
  width:85%;
  height:40px;
  padding:10px;
  margin-left:2%;
  margin-right:2%;
  margin-top:10px;
  margin-bottom:10px;
  display:inline-block;
  background-color: #fff !important;
  border:none;
}

.emailnew:focus {
    outline: none !important;
    border:1px solid #609966;
    box-shadow: 0 0 5px #40513B;
}


.loginpageheading{
  font-size: 30px;
  font-weight: bold;
  color: #40513B;
text-align: center;
}

.eye-iconnew {
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
}

.loginbottomsection {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.5rem;
  position: relative;
  top: 0;
  margin-top: 20px;
}

.logintbuttoncontainer{
  text-align: center;
}

#logintbutton{
  border: none;
  border-radius: 2px;
  padding: 12px 18px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: #40513B;
  box-shadow: 0 0 4px #999;
  outline: none;
  text-align: center;
}

.loginleftcolnew{
  background-color:#9DC08B ;
  min-height: 100vh;
}
.loginleftcolcontainer{
  min-height: 100vh;
}

.logoimgcontainer {
  margin-right: 40px;
  margin-left: 40px;
  /* box-shadow: 0px 0px 12px grey; */
  filter: drop-shadow(0px 0px 12px #609966);
  margin:0 auto;
  margin-top: 50vh;
  transform: translateY(-50%);
  text-align: center;
}
.registerlogoimg {
  width: 100%;
  height: 100%;
  background-color: transparent;
  max-width: 50%;
}

.loginrightcolnew{
  background-color:#EDF1D6;
  min-height: 100vh;
}

.loginrightsidenew{
  margin:0 auto;
  margin-top: 25vh;
  transform: translateY(-50%);
  text-align: left;
  max-width: 50%;
}



/* //////////////////// Menus Page //////////////// */
.menuspagecontainer{
  background-color:#EDF1D6;
  min-height: 100vh;
}

.restaurants-container{
  height: auto;   
  padding-left: 20px;
  padding-right: 10px;
  text-align: center;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.menuspageheading{
  margin-top: 5%;
  font-size: 30px;
  font-weight: bold;
  color: #40513B;
  text-align: center;
}


#menuspagebutton{
  border: none;
  border-radius: 2px;
  padding: 12px 18px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: #609966;
  box-shadow: 0 0 4px #999;
  outline: none;
}

.menuspagebuttoncontainer{
  text-align: center;
}

.qrscannercontainer{
  height: auto;   
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
}