.menuheading {
    font-size: 40px;
    font-weight: bold;
    color: #609966;
    text-align: left;
    margin-bottom: 10px;
  }


.rating {
    color: #ffc107;
    margin-bottom: 0.5rem;
}


.menu-sidebar {
    border-right: 1px solid #e9ecef;
}

.menu-sidebar ul {
    list-style-type: none;
    padding-left: 0;
}

.menu-sidebar li {
    margin-bottom: 0.5rem;
}

.menu-sidebar span {
    color: #495057;
    text-decoration: none;
    cursor: pointer;
}

.menu-section {
    margin-bottom: 2rem;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    background-color: #609966;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.section-header:hover {
    background-color: #9DC08B;
}

.section-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.section-content.expanded {
    max-height: 100vh;
    transition: max-height 1s ease-in;
}

.menu-item {
    margin-bottom: 1rem;
}

.menu-item .card-title a {
    color: #212529;
    text-decoration: none;
}

.item-details {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.price {
    font-weight: bold;
}

.section-note {
    font-style: italic;
    color: #6c757d;
    margin-top: 0.5rem;
}



.item-name-link{
    font-weight: bold;
  }
  