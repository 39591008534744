/* Sidebar */
.adminsidebar-nav {
    background-color: #333;
    color: white;
    padding-top: 20px;
  }
  
  .adminsidebar-nav .nav-link {
    color: white;
    padding: 10px 20px;
  }
  
  .adminsidebar-nav .nav-link.active {
    background-color: #555;
  }

  .adminapp-container {
    position: relative;    
    background-color: #f3f0ef;
}


.admincontent-container {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    z-index: 0;
}


  .admincontent {
    background-color: #f8f9fa;
    padding: 20px;
    min-height: calc(100vh - 56px);
  }
  
  /* Override Bootstrap container padding */
  .admincontent-container .container-fluid {
    padding: 0;
  }


  /* Style for the user table */
.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .user-table th,
  .user-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .user-table th {
    background-color: #f2f2f2;
  }
  

  .adminprofessionalprojthumbnails{
    width: 200px;
    height: 100px;
  }


  .adminsignout-button{
    justify-content: right;
    text-align: right;
  }


  .activebuttonadmin{
    background-color: #18903a;
    color: white;
    border-radius: 4px;
  }

  .pausedbuttonadmin{
    background-color: #d9534f;
    color: white;
    border-radius: 4px;
  }